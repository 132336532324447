import React from "react";
import styled from "styled-components";
import { theme } from "@project/shared";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #eaeff3;

  & .main-title {
    ${theme.typography.typographyConsumerCaptionMedium}
    margin-top: 60px;
    line-height: 28px;
    & > span {
      display: inline-block;
    }
  }
  & .desc {
    ${theme.typography.typographyConsumerText}
    margin-top:35px;
    padding: 0px 25px;
    & .desc-display-inline {
      display: inline-block;
    }
  }
  & .link {
    ${theme.typography.typographyConsumerCaptionMedium}
    color: black;
    font-size: 14px;
    margin-top: 24px;
    padding-bottom: 3px;
    text-decoration: underline;
    text-underline-offset: 3px;
    cursor: unset;
    & > span:hover {
      cursor: pointer;
      color: ${theme.consumerLinkColor};
    }
  }
  & .imgwrapper {
    display: flex;
    justify-content: center;
    & > img {
      width: 31vw;
      height: 31vw;
      object-fit: cover;
      border-radius: 5px;
    }
    & img:not(:first-child) {
      margin-left: 12.25px;
    }
  }

  & .bottom-img-wrapper {
    margin-top: 60px;
  }
  & .hide-mobile-view {
    display: none;
  }

  @media (min-width: 768px) {
    & .imgwrapper {
      & img {
        height: 18.8vw;
        width: 18.8vw;
      }
    }
    & .hide-mobile-view {
      display: unset;
    }
  }
  @media (min-width: 1024px) {
    & .display-none {
      display: none;
    }
    & .imgwrapper {
      & img {
        height: 195px;
        width: 195px;
      }
    }
  }
`;

export const MiddleSeciton = () => {
  return (
    <SectionWrapper>
      <div className={"imgwrapper imgwrapper-desktop"}>
        {imageTopData?.map((img, idx) => (
          <img
            src={img}
            key={`${idx}_image-top`}
            alt={`Image ${idx}`}
            loading={"lazy"}
            className={idx > 2 ? "hide-mobile-view" : ""}
          />
        ))}
      </div>
      <p className={"main-title"}>
        {"日野町は、見どころ"}
        <span>{"たっぷりです。"}</span>
      </p>
      <div className={"desc"}>
        <p>
          {"近江日野商人の歩み、綿向山、ブルーメの丘、"}
          <br />
          {"グルメスポットなど日野町を"}
          <span className={"desc-display-inline"}>
            {"楽しんで周遊できるスタンプラリー"}
          </span>
        </p>
        <p>{"やお得なクーポンもあります。"}</p>
      </div>
      <a
        className={"link"}
        href={"https://www.hino-kanko.jp"}
        target={"_blank"}
      >
        <span>{"魅力満載の日野の観光情報はこちら"}</span>
      </a>
      <a
        className={"link"}
        href={"http://www.town.shiga-hino.lg.jp"}
        target={"_blank"}
      >
        <span>{"日野町の情報はこちら"}</span>
      </a>
      <div className={"imgwrapper bottom-img-wrapper"}>
        <div className={"imgwrapper imgwrapper-desktop"}>
          {imageButtomData?.map((img, idx) => (
            <img
              src={img}
              key={`${idx}_image-top`}
              alt={`Image ${idx}`}
              loading={"lazy"}
              className={idx > 2 ? "hide-mobile-view" : ""}
            />
          ))}
        </div>
      </div>
    </SectionWrapper>
  );
};

const imageTopData = [
  "/images/middlesection/wat1n.jpg",
  "/images/middlesection/wat2n.jpg",
  "/images/middlesection/wat3n.jpg",
  "/images/middlesection/wat4n.jpg",
  "/images/middlesection/wat5n.jpg",
];

const imageButtomData = [
  "/images/middlesection/wat6n.jpg",
  "/images/middlesection/wat7n.jpg",
  "/images/middlesection/wat8n.jpg",
  "/images/middlesection/wat9n.jpg",
  "/images/middlesection/wat10n.jpg",
];
