import React from "react";
import styled from "styled-components";
import { iStepCardItem } from "../../../interface";

interface iStepCard extends iStepCardItem {
  titleIndex: string;
  isAlignLeft: boolean;
}

const FirstContainer = styled.div`
  font-family: "yugothic", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const SecondContainer = styled.div`
  font-family: "yugothic", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1300px) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
`;
const RightTextSection = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;
const LeftTextSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const LeftHeadedTitle = styled.div`
  width: 500px;
  height: 94px;
  background: #e6efe3;
  font-weight: 700;
  font-size: 36px;
  line-height: 58px;
  color: #1a1a1a;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0% 0%, 75% 0%, 90% 50%, 75% 100%, 0% 100%);
  .green-text {
    color: #38a412;
  }
  @media (max-width: 561px) {
    width: 275px;
    height: 70px;
    font-size: 25px;
    line-height: normal;
  }
`;
const RightHeadedTitle = styled.div`
  width: 500px;
  height: 94px;
  background: #e6efe3;
  font-weight: 700;
  font-size: 36px;
  line-height: 58px;
  color: #1a1a1a;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 10% 50%);
  .text {
    padding-left: 115px;
  }
  .green-text {
    color: #38a412;
  }
  @media (max-width: 561px) {
    width: 275px;
    height: 70px;
    font-size: 25px;
    line-height: normal;
  }
`;
const Content = styled.div`
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  margin-top: 45px;
  margin-left: 116px;
  white-space: break-spaces;
  @media (max-width: 786px) {
    margin-left: 50px;
  }
  @media (max-width: 561px) {
    font-size: 22px;
    line-height: normal;
    margin-top: 20px;
    margin-left: 20px;
  }
  @media (max-width: 425px) {
    font-size: 20px;
  }
  @media (max-width: 350px) {
    font-size: 18px;
  }
`;
const LeftImageSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10%;

  @media (max-width: 1300px) {
    width: 100%;
    margin: auto;
    margin-top: 40px;
    margin-right: 0%;
  }
  @media (max-width: 678px) {
    flex-direction: column;
    .step-img {
      width: 80%;
      object-fit: contain;
    }
  }
  @media (max-width: 520px) {
    .step-img {
      height: 320px;
    }
  }
  @media (max-width: 425px) {
    margin-top: 20px;
  }
`;
const RightImageSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10%;
  @media (max-width: 1300px) {
    width: 100%;
    margin: auto;
    margin-top: 40px;
    margin-left: 0%;
  }
  @media (max-width: 678px) {
    img {
      width: 80%;
      object-fit: contain;
    }
  }
  @media (max-width: 520px) {
    img {
      height: 320px;
    }
  }
  @media (max-width: 425px) {
    margin-top: 20px;
  }
`;
const ContentRight = styled.div`
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  margin-top: 45px;
  margin-right: 116px;
  white-space: break-spaces;
  text-align: end;
  @media (max-width: 786px) {
    margin-right: 50px;
  }

  @media (max-width: 561px) {
    font-size: 22px;
    line-height: normal;
    margin-top: 20px;
    margin-right: 20px;
  }
  @media (max-width: 425px) {
    font-size: 20px;
  }
  @media (max-width: 350px) {
    font-size: 18px;
  }
`;

const StepCard: React.FC<iStepCard> = ({
  title,
  titleIndex,
  content,
  imgSrc,
  isAlignLeft,
  extraButton,
}) => {
  if (isAlignLeft) {
    return (
      <FirstContainer>
        <LeftTextSection>
          <LeftHeadedTitle>
            <span className={"text"}>
              {title} <span className={"green-text"}>{titleIndex}</span>
            </span>
          </LeftHeadedTitle>
          <Content>{content}</Content>
        </LeftTextSection>
        <LeftImageSection>
          {extraButton}
          <img className={"step-img"} src={imgSrc} height={"525px"} />
        </LeftImageSection>
      </FirstContainer>
    );
  }
  return (
    <SecondContainer>
      <RightImageSection>
        <img className={"step-img"} src={imgSrc} height={"525px"} />
      </RightImageSection>
      <RightTextSection>
        <RightHeadedTitle>
          <span>
            {title} <span className={"green-text"}>{titleIndex}</span>
          </span>
        </RightHeadedTitle>
        <ContentRight>{content}</ContentRight>
      </RightTextSection>
    </SecondContainer>
  );
};

export { StepCard };
