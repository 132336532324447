import React from "react";
import styled from "styled-components";
import { iStepCardItem } from "../../../../interface";
import { StepCard } from "../../../molecules";

const Title = styled.div`
  font-family: "yugothic", sans-serif;
  font-weight: 700;
  font-size: 42px;
  line-height: 44px;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 30px;
  @media (max-width: 786px) {
    font-size: 30px;
    line-height: normal;
  }
  @media (max-width: 561px) {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  @media (max-width: 459px) {
    font-size: 22px;
  }
  @media (max-width: 389px) {
    margin-top: 50px;
    margin-bottom: 45px;
    font-size: 20px;
  }
`;
const Container = styled.div`
  display: grid;
  row-gap: 40px;
  max-width: 1520px;
  margin: auto;
  @media (max-width: 425px) {
    row-gap: 30px;
  }
`;
const Step2 = styled.div`
  img {
    @media (max-width: 520px) {
      height: 20px;
    }
    @media (max-width: 425px) {
      height: 18px;
    }
  }
`;
const ExtraButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 60px;
  img {
    object-fit: contain;
  }
  @media (max-width: 687px) {
    margin-right: 0px;
    margin-bottom: 30px;
  }
`;

const steps: iStepCardItem[] = [
  {
    title: "ステップ",
    content: "ぐるりん日野ナビアプリ\nをダウンロード",
    imgSrc: "/images/stamprally/step1.png",
    extraButton: (
      <ExtraButton>
        <a
          href={"https://play.google.com/store/apps/details?id=jp.watamuki"}
          target={"_blank"}
        >
          <img
            src={"/images/stamprally/playstore.png"}
            alt={"Play store image"}
            height={"85px"}
            width={"185px"}
            loading={"lazy"}
          />
        </a>
        <a
          href={
            "https://apps.apple.com/jp/app/%E3%81%90%E3%82%8B%E3%82%8A%E3%82%93%E6%97%A5%E9%87%8E%E3%83%8A%E3%83%93/id1608286321?l=en"
          }
          target={"_blank"}
        >
          <img
            src={"/images/stamprally/appstore.png"}
            alt={"App store image"}
            height={"85px"}
            width={"185px"}
            loading={"lazy"}
          />
        </a>
      </ExtraButton>
    ),
  },
  {
    title: "ステップ",
    content: (
      <Step2>
        {"ぐるりん日野ナビ上 のスタンプ"}
        <img src={"/images/stamprally/pin.png"} />
        {"で"}
        <br />
        {"スタンプポイントの場所を確認"}
      </Step2>
    ),
    imgSrc: "/images/stamprally/step2.png",
  },
  {
    title: "ステップ",
    content: "スタンプポイントにチェックイン\nしスタンプを獲得",
    imgSrc: "/images/stamprally/step3.png",
  },
  {
    title: "ステップ",
    content: "お得なクーポンを使って、\n日野を満喫しよう♪",
    imgSrc: "/images/stamprally/step4.png",
  },
];
const FlowRally: React.FC = () => {
  return (
    <div>
      <Title>{"スタンプラリーご利用の流れ"}</Title>
      <Container>
        {steps.map((step, index) => (
          <StepCard
            title={step.title}
            titleIndex={String(index + 1)}
            key={index}
            imgSrc={step.imgSrc}
            content={step.content}
            isAlignLeft={(index + 1) % 2 === 1}
            extraButton={step.extraButton}
          />
        ))}
      </Container>
    </div>
  );
};

export { FlowRally };
