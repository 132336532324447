import React from "react";
import styled from "styled-components";
import Link from "next/link";
const image5 = require("../../../../public/images/stamprally/image5.png");
const image6 = require("../../../../public/images/stamprally/image6.png");
const image7 = require("../../../../public/images/stamprally/image7.png");
const image8 = require("../../../../public/images/stamprally/image8.png");

const Main = styled.div`
  background: #f2f2f2;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 40px;
  max-width: 1520px;
  margin: auto;
  margin-top: 60px;
  @media (max-width: 786px) {
    padding-top: 30px;
  }
`;
const TextWrapper = styled.div`
  font-family: "yugothic", sans-serif;
  font-weight: 700;
  font-size: 42px;
  line-height: 66px;
  text-align: center;
  color: #000000;

  @media (max-width: 1060px) {
    padding: 0 20px;
  }

  @media (max-width: 786px) {
    font-size: 30px;
    line-height: normal;
  }
  @media (max-width: 559px) {
    white-space: break-spaces;
  }
  @media (max-width: 459px) {
    font-size: 22px;
  }
  @media (max-width: 389px) {
    font-size: 20px;
  }
`;
const ParagraphWrapper = styled.div`
  font-family: "yugothic", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-decoration: none;
  color: #38a412;
  margin-top: 30px;

  .link-text {
    border-bottom: 1.5px solid #38a412;
  }

  @media (max-width: 1060px) {
    padding: 0 20px;
  }

  @media (max-width: 786px) {
    margin-top: 30px;
  }

  @media (max-width: 459px) {
    font-size: 20px;
  }
  @media (max-width: 459px) {
    font-size: 20px;
  }
  @media (max-width: 360px) {
    font-size: 16px;
  }
`;
const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 18px;
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;

  @media (max-width: 800px) {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 30px;
  }
  @media (max-width: 786px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

    & > div :last-child {
      display: none;
    }
  }
  @media (max-width: 678px) {
    margin-left: 8px;
    margin-right: 8px;
  }
  @media (max-width: 425px) {
    margin-left: 5px;
    margin-right: 5px;
  }
`;
const ImageWrapper = styled.div`
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: block;
  }
`;

const NatureSection = () => {
  return (
    <Main>
      <Container>
        <TextWrapper>
          {"町内の豊かな自然や文化に\n触れながら"}
          <br />
          {"日野の魅力を体感してください"}
        </TextWrapper>
        <ParagraphWrapper>
          <Link href={"https://www.hino-kanko.jp/"}>
            <a
              className={"link-text"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              {"日野町観光情報サイトはこちらから"}
            </a>
          </Link>
        </ParagraphWrapper>
        <ImageContainer>
          <ImageWrapper>
            <img src={image5} alt={"image"} />
          </ImageWrapper>
          <ImageWrapper>
            <img src={image6} alt={"image"} />
          </ImageWrapper>
          <ImageWrapper>
            <img src={image7} alt={"image"} />
          </ImageWrapper>
          <ImageWrapper>
            <img src={image8} alt={"image"} />
          </ImageWrapper>
        </ImageContainer>
      </Container>
    </Main>
  );
};

export { NatureSection };
