import React from "react";
import styled from "styled-components";
const image1 = require("../../../../public/images/stamprally/image1.png");
const image2 = require("../../../../public/images/stamprally/image2.png");
const image3 = require("../../../../public/images/stamprally/image3.png");
const image4 = require("../../../../public/images/stamprally/image4.png");

const Main = styled.div`
  background: #f2f2f2;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  max-width: 1520px;
  margin: auto;

  @media (max-width: 561px) {
    padding-bottom: 10px;
  }
`;

const TextWrapper = styled.div`
  font-family: "yugothic", sans-serif;
  font-weight: 700;
  font-size: 42px;
  line-height: 57px;
  text-align: center;
  color: #000000;
  padding-top: 30px;

  @media (max-width: 870px) {
    padding: 30px 20px 0px 20px;
    white-space: break-spaces;
  }

  @media (max-width: 786px) {
    font-size: 30px;
    line-height: normal;
  }
  @media (max-width: 636px) {
    white-space: break-spaces;
  }
  @media (max-width: 459px) {
    font-size: 22px;
  }
  @media (max-width: 389px) {
    font-size: 20px;
  }
`;
const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 18px;
  margin-top: 20px;
  margin-left: 100px;
  margin-right: 100px;

  @media (max-width: 786px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

    & > div :last-child {
      display: none;
    }
  }

  @media (max-width: 800px) {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 30px;
  }

  @media (max-width: 678px) {
    margin-left: 8px;
    margin-right: 8px;
  }
  @media (max-width: 425px) {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

const ImageWrapper = styled.div`
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: block;
  }
`;

const WelcomeSection = () => {
  return (
    <Main>
      <Container>
        <TextWrapper>
          {"さぁ日野の魅力を発見する旅に\n出かけよう！"}
        </TextWrapper>
        <ImageContainer>
          <ImageWrapper>
            <img src={image1} alt={"image"} />
          </ImageWrapper>
          <ImageWrapper>
            <img src={image2} alt={"image"} />
          </ImageWrapper>
          <ImageWrapper>
            <img src={image3} alt={"image"} />
          </ImageWrapper>
          <ImageWrapper>
            <img src={image4} alt={"image"} />
          </ImageWrapper>
        </ImageContainer>
      </Container>
    </Main>
  );
};

export { WelcomeSection };
