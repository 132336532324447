import React from "react";
import styled from "styled-components";
import { theme } from "@project/shared";

interface iFooter {
  isStampRally?: boolean;
}

const Container = styled.div<iFooter>`
  background-color: #eaeff3;
  margin-top: 40px;
  padding: 20px;
  text-align: center;

  & .footer-bottom-title {
    font-size: 10px;
    line-height: 20px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
  }

  & .text-link {
    margin-bottom: 10px;
    text-decoration: underline;
    text-underline-offset: 3px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    > a:hover {
      color: ${theme.consumerLinkColor};
    }
  }

  @media (max-width: 1023px) {
    margin-top: ${({ isStampRally }) => (isStampRally ? "0px" : "40px")};
  }
`;

export const FooterSection: React.FC<iFooter> = ({ isStampRally }) => {
  return (
    <Container isStampRally={isStampRally}>
      <div className={"text-link"}>
        <a href={"mailto:app＠town.shiga-hino.lg.jp"} target={"_blank"}>
          <span style={theme.typography.typographyConsumerCaptionSmall}>
            {"お問い合わせ窓口はこちら"}
          </span>
        </a>
        <a href={"/privacy-policy"}>
          <span style={theme.typography.typographyConsumerCaptionSmall}>
            {"プライバシーポリシー"}
          </span>
        </a>
      </div>
      <p className={"footer-bottom-title"}>
        {"Copyright © Hino Town All Rights Reserved."}
      </p>
    </Container>
  );
};
