import { theme } from "@project/shared";
import React from "react";
import styled from "styled-components";
const banner = require("../../../public/images/banner.jpeg");

const Container = styled.div`
  position: relative;
  background-image: url(${banner});
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 85px 0 75px;

  & .logo-bottom {
    ${theme.typography.typographyConsumerCaptionBig}
    margin-top:10px;
    margin-bottom: 30px;
    font-size: 28px;
    line-height: 32px;
    & > span {
      ${theme.typography.typographyConsumerText}
      display: block;
      font-size: 12px;
    }
  }
`;

const Content = styled.div`
  text-align: center;
  color: white;
  font-size: 14px;
  line-height: 2.4;

  @media (max-width: 1024px) {
    .marginSet {
      margin-bottom: 20px;
    }
    span {
      display: inherit;
      line-height: 1.7;
    }
  }
`;

const Logo = styled.img`
  text-align: center;
  margin-top: 15px;
  width: 100px;
  vertical-align: bottom;
  @media (max-width: 1024px) {
    margin-top: 10px;
    width: 125px;
  }
`;

export const BannerSection = () => {
  return (
    <>
      <Container>
        <Content>
          <Logo src={"/images/logo.png"} alt={"Banner image"} />
          <p className={"logo-bottom"}>
            {"「ぐるりん日野ナビ」"}
            <span>{"HINO TOWN"}</span>
          </p>
          <p>{"湖東の奥座敷、日野町。"}</p>
          <p>
            {"霊峰綿向山にいだかれ、"}
            <span>{"育まれてきた悠久の歴史と文化、"}</span>
          </p>
          <p>
            <span>{"四季折々の美しい装いを見せる豊かな自然。"}</span>
          </p>
          <p>
            {"近江日野商人の三方よしの教えと"}
            <span className={"marginSet"}>{"進取の気風が息づく、"}</span>
          </p>
          <p>
            {"日野町をゆっくり、"}
            <span>{"お楽しみいただける"}</span>
            {"日野町の見どころ満載のアプリです。"}
          </p>
        </Content>
      </Container>
    </>
  );
};
