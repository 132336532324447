import React from "react";
import styled from "styled-components";
const stampRallyBanner = require("../../../../public/images/stamprally/stamprallybanner.jpg");

const Logo = styled.img`
  text-align: center;
  margin: 15px 0px;
  width: 100px;
  vertical-align: bottom;
  @media (max-width: 1024px) {
    margin-top: 10px;
    width: 125px;
  }
`;
const LogoText = styled.div`
  text-align: center;
  color: white;
  font-size: 14px;
  line-height: 2.4;
  & .logo-bottom {
    font-family: "yugothic", sans-serif;
    margin-top: 10px;
    margin-bottom: 65px;
    font-size: 28px;
    line-height: 32px;
    & > span {
      display: block;
      font-size: 12px;
    }
  }
  @media (max-width: 1024px) {
    .marginSet {
      margin-bottom: 20px;
    }
    span {
      display: inherit;
      line-height: 1.7;
    }
  }
`;
const Container = styled.div`
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25)),
    url(${stampRallyBanner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 100px 0px 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #ffffff;

  @media (max-width: 786px) {
    align-items: center;
  }
`;
const OverlayTextWrapper = styled.div`
  font-family: "yugothic", sans-serif;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 34px;

  @media (max-width: 1060px) {
    padding: 0 20px;
  }
  @media (max-width: 786px) {
    margin-bottom: 0px;
  }
  @media (max-width: 561px) {
    white-space: break-spaces;
  }
  @media (max-width: 370px) {
    font-size: 12px;
  }
`;
const StampRallyBanner = () => {
  return (
    <Container>
      <LogoText>
        <Logo src={"/images/logo.png"} alt={"Banner image"} />
        <p className={"logo-bottom"}>
          {"「ぐるりん日野ナビ」"}
          <span>{"HINO TOWN"}</span>
        </p>
      </LogoText>
      <OverlayTextWrapper>
        {
          "日野の町は、\n戦国時代蒲生家が礎を築き、\n近江日野商人が全国で活躍する"
        }
        <br />
        {"ことで発展しました。\n町の最大の魅力は恵まれた自然と美しい景色です。"}
        <br />
        {"標高1,110mの雄大にそびえる霊峰綿向山や、"}
        <br />
        {"四季折々に咲く花の景色は心を和ませてくれます。"}
      </OverlayTextWrapper>
    </Container>
  );
};
export { StampRallyBanner };
