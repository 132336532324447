import React from "react";
import styled from "styled-components";
import { theme } from "@project/shared";

interface iButtonSection {
  isBottomOnly?: boolean;
}

const BottomWrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  & .top-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & .bottom-section {
    display: none;
  }

  & .bottomlogo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > img {
      width: 120px;
      margin: auto;
    }
    & .logo-bottom {
      ${theme.typography.typographyConsumerCaptionBig}
      margin-top:10px;
      font-size: 28px;
      line-height: 32px;
      & > span {
        ${theme.typography.typographyConsumerText}
        display: block;
        font-size: 10px;
        text-align: center;
      }
    }
  }

  & .top-left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & .top-right-img-wrapper {
    display: none;
  }
  & .responsive-image-top-section {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    & img {
      width: 85%;
    }
  }
  & .desc-top {
    text-align: center;
    margin-top: 24px;
    ${theme.typography.typographyConsumerText}
    & .desc-top-display-inline {
      display: inline-block;
    }
  }
  & .share-wrapper {
    margin-top: 40px;
    & > a {
      margin-left: 5px;
      margin-right: 5px;
      & > img {
        width: 135px;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }
  @media (max-width: 420px) {
    & .desc-top {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  @media (min-width: 768px) {
    & .responsive-image-top-section {
      display: flex;
      & img {
        width: 328px;
      }
    }
  }
  @media (min-width: 1024px) {
    & .top-section {
      flex-direction: row;
      margin: auto;
      justify-content: space-between;
      width: 800px;
    }

    & .top-right-img-wrapper {
      display: flex;
      & > img {
        width: 400px;
        object-fit: contain;
      }
    }
    & .responsive-image-top-section {
      display: none;
    }
    & .display-none {
      display: none;
    }

    & .bottom-section {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      & .each-bottom-part {
        display: flex;
        justify-content: center;
        & > img {
          width: 128px;
          object-fit: contain;
        }
        & :nth-child(2) {
          margin-left: 95px;
        }
        & .content {
          display: flex;
          flex-direction: column;
          padding: 15px 0px 0px 20px;
          & .bottom-content-title {
            ${theme.typography.typographyConsumerCaptionMedium}
            line-height: 34px;
          }
          & .bottom-content-desc {
            ${theme.typography.typographyConsumerText}
            white-space: pre-line;
            margin-top: 20px;
            line-height: 18px;
          }
        }
      }
    }
  }
`;
export const BottomSeciton: React.FC<iButtonSection> = ({ isBottomOnly }) => {
  return (
    <BottomWrapper>
      {!isBottomOnly && (
        <div className={"top-section"}>
          <div className={"top-left"}>
            <div className={"bottomlogo-wrapper"}>
              <img src={"/images/logo.png"} alt={"Logo image"} />
              <p className={"logo-bottom"}>
                {"「ぐるりん日野ナビ」"}
                <span>{"HINO TOWN"}</span>
              </p>
            </div>
            <div className={"responsive-image-top-section"}>
              <img src={"/images/bottomsection/phone3n.png"} />
            </div>
            <div className={"desc-top"}>
              <p>{" 日野町での周遊・魅力発見をサポートする"}</p>
              <p>{"日野町の公式アプリです。"}</p>
              <p>
                {"おすすめスポットやスタンプラリー、"}
                <span className={"desc-top-display-inline"}>
                  {"お得なクーポンなど、"}
                </span>
              </p>
              <p>{"魅力あふれる日野町の楽しみ方を紹介します。"}</p>
            </div>
            <div className={"share-wrapper"}>
              <a
                href={
                  "https://play.google.com/store/apps/details?id=jp.watamuki"
                }
                target={"_blank"}
              >
                <img
                  src={"/images/bottomsection/gplay.png"}
                  alt={"Play store image"}
                  height={"40px"}
                  width={"135px"}
                  loading={"lazy"}
                />
              </a>
              <a
                href={
                  "https://apps.apple.com/us/app/%E3%81%90%E3%82%8B%E3%82%8A%E3%82%93%E6%97%A5%E9%87%8E%E3%83%8A%E3%83%93/id1608286321"
                }
                target={"_blank"}
              >
                <img
                  src={"/images/bottomsection/appstore.png"}
                  alt={"App store image"}
                  height={"40px"}
                  width={"135px"}
                  loading={"lazy"}
                />
              </a>
            </div>
          </div>
          <div className={"top-right-img-wrapper"}>
            <img
              src={"/images/bottomsection/phone3n.png"}
              width={"328px"}
              height={"324px"}
              loading={"lazy"}
              alt={"Phone image"}
            />
          </div>
        </div>
      )}
      <div className={"bottom-section"}>
        {bottomData?.map((item, idx) => (
          <div className={"each-bottom-part"} key={`${item?.subTitle}_${idx}`}>
            <img src={item.image} alt={`Cellphone ${idx}`} loading={"lazy"} />
            <div className={"content"}>
              <p className={"bottom-content-title"}>{item.title}</p>
              <p className={"bottom-content-title"}>{item.subTitle}</p>
              <p className={"bottom-content-desc"}>{item.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </BottomWrapper>
  );
};

const bottomData = [
  {
    title: "ゆるっと周ろう",
    subTitle: "スタンプラリー",
    desc: `魅力あふれる\n 日野町のスポットをめぐる\n スタンプラリー。\n 徒歩やサイクリングなど \n さまざまなかたちで周遊いただけます。`,
    image: "/images/bottomsection/phone1n.png",
  },
  {
    title: "お得なご当地",
    subTitle: "クーポン",
    desc: `歴史ある名所、\n 特産、サービスを手軽に \n 利用できるお得なクーポン \nをご案内します。`,
    image: "/images/bottomsection/phone2n.png",
  },
];
